import React, { useMemo, useState } from 'react';

import { ExportToCsv } from 'export-to-csv';
import {
  MaterialReactTable,
  type MRT_Icons,
  type MRT_RowData,
  MRT_ShowHideColumnsButton,
  type MRT_TableOptions,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_HY } from 'material-react-table/locales/hy';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../src/application/icons';
import { useEvent } from '../utils';

import { PaginationTable } from './components/pagination-table';

import styles from './index.module.scss';

const CUSTOM_ICONS: Partial<MRT_Icons> = {
  FilterListIcon: () => <Icon className={styles.icon} name="FilterXt" />,
  FilterListOffIcon: () => <Icon className={styles.icon} name="FilterXt" />,
};

export type TableProps<T extends MRT_RowData = MRT_RowData> = {
  currentPage?: number;
  labelPagination?: string;
  locale?: string;
  isLastPage?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  isExportButtonActive?: boolean;
  IconButtonTable?: JSX.Element[];
  showFullScreenButton?: boolean;
  showInitialExpandColumn?: boolean;
  enableHiding: boolean;
  onPageChange?: (page: number, offset?: number) => void;
  onFilterClick?: VoidFunction;
} & MRT_TableOptions<T>;

export const Table = <T extends MRT_RowData = MRT_RowData>({
  currentPage,
  columns,
  data,
  labelPagination,
  isLastPage,
  isLoading: isLoadingData,
  isFetching: isFetchingData = isLoadingData,
  isExportButtonActive,
  state,
  locale,
  showFullScreenButton,
  IconButtonTable: IconButtonTable = [],
  enablePagination,
  enableHiding = true,
  enableRowVirtualization = false,
  enableExpanding = false,
  enableExpandAll = false,
  showInitialExpandColumn = true,
  onPageChange,
  onFilterClick,
  ...materialReactTableProps
}: TableProps<T>) => {
  const { t } = useTranslation();
  const [prevData, setPrevData] = useState(data);

  const csvExporter = useMemo(
    () =>
      new ExportToCsv({
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns?.map(({ header }) => header),
      }),
    [columns]
  );

  const handleExportData = useEvent(() => csvExporter.generateCsv(data));

  const hasData = !!data?.length;

  const isLoading = isLoadingData || (!hasData && isFetchingData);

  // TODO: не выводить паджинацию если получена неполная страница данных
  const hasPagination = useMemo(() => {
    const isDataChanged = data !== prevData;
    if (!isFetchingData) {
      setPrevData(data);
    }
    return hasData && (isFetchingData ? true : isDataChanged);
    // we need to update hasPagination only after finish request
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingData]);

  const localization = useMemo(() => {
    switch (locale) {
      case 'hy':
        return MRT_Localization_HY;
      case 'ru':
        return MRT_Localization_RU;
      case 'en':
        return MRT_Localization_EN;
      default:
        return MRT_Localization_RU;
    }
  }, [locale]);

  const initialState = React.useMemo(
    () => ({
      columnVisibility: {
        'mrt-row-expand': showInitialExpandColumn,
      },
    }),
    [showInitialExpandColumn]
  );

  return (
    <Box className={styles.tableWrap}>
      <MaterialReactTable<T>
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnVirtualization={true} // Table falls if enableColumnVirtualization is dynamic, its library error, checked until version 2.13 Table alighment is incorrect if enableColumnVirtualization={false}
        enableExpandAll={enableExpandAll}
        enableExpanding={enableExpanding}
        enableGlobalFilter={false}
        enableHiding={enableHiding}
        enablePagination={enablePagination ?? false}
        enableRowVirtualization={enableRowVirtualization}
        icons={CUSTOM_ICONS}
        initialState={initialState}
        localization={localization}
        muiTableBodyCellProps={{
          sx: {
            display: 'block',
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '10px',
            paddingRight: '10px',
          },
        }}
        muiTableContainerProps={{ sx: { maxHeight: 'none' } }}
        muiTableHeadRowProps={{ sx: { boxShadow: 'none' } }}
        muiTablePaperProps={{ elevation: 0, sx: { border: 'none' } }}
        renderToolbarInternalActions={({ table }) => (
          <Box>
            {table.getAllColumns().length > 1 && enableHiding && (
              <MRT_ShowHideColumnsButton
                className={styles.button}
                color="primary"
                table={table}
              />
            )}
            {onFilterClick && (
              <MRT_ToggleFiltersButton
                className={styles.button}
                color="primary"
                table={table}
                onClick={onFilterClick}
              />
            )}
            {isExportButtonActive && (
              <Tooltip arrow title={t('Экспорт')}>
                <IconButton color="primary" onClick={handleExportData}>
                  <Icon name="DownloadMedium" />
                </IconButton>
              </Tooltip>
            )}
            {showFullScreenButton && (
              <MRT_ToggleFullScreenButton
                className={styles.button}
                color="primary"
                table={table}
              />
            )}
            {IconButtonTable}
          </Box>
        )}
        state={{
          ...state,
          isLoading,
        }}
        {...materialReactTableProps}
        renderBottomToolbarCustomActions={({ table }) => {
          const hasVisibleColumns = table.getVisibleFlatColumns().length > 0;
          return (
            <Box className={styles.paginationTable}>
              {hasVisibleColumns &&
                onPageChange &&
                currentPage !== undefined &&
                labelPagination &&
                (isLastPage === undefined
                  ? hasPagination
                  : isLastPage !== true) && (
                  <PaginationTable
                    currentPage={currentPage}
                    isLoading={isFetchingData}
                    label={labelPagination}
                    onPageChange={onPageChange}
                  />
                )}
            </Box>
          );
        }}
      />
    </Box>
  );
};
