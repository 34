import React, { FC } from 'react';

import { FieldRenderProps } from 'react-final-form';

import { useEvent } from '../../utils/react.hooks';
import { TextField, type TextFieldProps } from '../../mui/textfield/textfield';

export type TextFormFieldProps = TextFieldProps & FieldRenderProps<string>;

type Props = Required<TextFormFieldProps>;

export const TextFormField: FC<TextFormFieldProps> = (props) => {
  const {
    input: {
      name,
      value,
      type,
      onBlur: onBlurField,
      onChange: onChangeField,
      onFocus: onFocusField,
    },
    meta,
    onChange,
    onBlur,
    onFocus,
    ...textFieldProps
  } = props;

  const hasError = meta.touched && Boolean(meta.error);
  const helperText =
    hasError && typeof meta.error === 'string' ? meta.error : undefined;

  const handleBlur = useEvent<Props['onBlur']>((event) => {
    onBlurField(event);
    onBlur?.(event);
  });

  const handleFocus = useEvent<Props['onFocus']>((event) => {
    onFocusField(event);
    onFocus?.(event);
  });

  const handleChange = useEvent<Props['onChange']>((event) => {
    onChangeField(event);
    onChange?.(event);
  });

  return (
    <TextField
      {...textFieldProps}
      error={hasError}
      helperText={helperText}
      name={name}
      type={type}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
    />
  );
};
