import React, { useState, useMemo, useCallback } from 'react';

import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack, TabProps, Typography } from '@mui/material';
import { type FormState, type FormApi, type Mutator } from 'final-form';
import { Form, FormSpy } from 'react-final-form';
import { endOfDay } from 'date-fns';

import { History, FilterChip } from '../history/history';
import { TabsComponent } from '../../../mui/tabs';
import { Button } from '../../../mui/button';
import { Drawer } from '../../../application/drawer';
import { constVoid } from '../../../utils/helpers';
import { Language } from '../../../application/datepicker/datepicker';
import {
  fillRef,
  useCheckIsMounted,
  useEvent,
} from '../../../utils/react.hooks';
import { Icon } from '../../../application/icons';
import { validators } from '../../../final-form/validators';

import {
  FieldByTypeProps,
  FilterByType,
  FilterType,
} from './assets/filter-by-type';

import styles from './filters-table.module.scss';

export type Filter = {
  id: string;
  value: string;
  filterLabel: string;
  filterValue?: string;
  type: FilterType;
};

export type FilterSet = {
  id: string;
  name: string;
  filters: Filter[];
  tab?: string;
};

export type FilterConfig = FieldByTypeProps & {
  isFilterHidden?: boolean;
};

export type FormValues = Record<
  string,
  boolean | string | { value: string; label?: string }
>;

const getTranslateFormValues2Filters =
  (filterConfigs: FilterConfig[]) =>
  (formValues: FormValues = {}): Filter[] =>
    Object.entries(formValues)
      .map(([key, formValue]) => {
        if (
          formValue === undefined ||
          formValue === null ||
          formValue === '' ||
          (Array.isArray(formValue) && formValue.length === 0)
        ) {
          return undefined;
        }

        const filterConfig = filterConfigs.find(
          ({ id, config = {} }) =>
            id === key || config?.keyTo === key || config?.keyFrom === key
        );

        switch (filterConfig?.type) {
          case FilterType.DATE_RANGE:
            if (
              filterConfig.config.keyFrom === key &&
              typeof formValue === 'string'
            ) {
              return {
                id: filterConfig.config.keyFrom,
                value: formValue,
                filterLabel: filterConfig.config.startLabel,
                filterValue: formValue,
                type: filterConfig.type,
              };
            }
            if (
              filterConfig.config.keyTo === key &&
              typeof formValue === 'string'
            ) {
              const value = endOfDay(new Date(formValue)).toISOString();
              return {
                id: filterConfig.config.keyTo,
                value,
                filterLabel: filterConfig.config.endLabel,
                filterValue: value,
                type: filterConfig.type,
              };
            }
            break;
          case FilterType.TEXT:
            return {
              id: key,
              value: formValue,
              filterLabel: filterConfig.label,
              filterValue: formValue,
              type: filterConfig.type,
            };
          case FilterType.CHECKBOX:
            return formValue === false
              ? undefined
              : {
                  id: key,
                  value: formValue,
                  filterLabel: filterConfig.label,
                  type: filterConfig.type,
                };
          case FilterType.SELECT:
            return {
              id: key,
              value: formValue,
              filterLabel: filterConfig.label,
              filterValue: filterConfig?.config?.options?.find(
                ({ value }) => value === formValue
              )?.label,
              type: filterConfig.type,
            };
          case FilterType.AUTOCOMPLETE:
          case FilterType.AUTOCOMPLETE_FREESOLO:
          case FilterType.AUTOCOMPLETE_SELECT:
          case FilterType.AUTOCOMPLETE_ASYNC:
          case FilterType.AUTOCOMPLETE_ASYNC_FREESOLO:
            return {
              id: key,
              value:
                typeof formValue === 'object' ? formValue.value : formValue,
              filterLabel: filterConfig.label,
              filterValue:
                typeof formValue === 'object'
                  ? formValue.label
                  : filterConfig.config?.options?.find(
                      (option) => option.value === formValue
                    )?.label ?? formValue,
              type: filterConfig.type,
            };
          case FilterType.AUTOCOMPLETE_MULTIPLE:
          case FilterType.AUTOCOMPLETE_ASYNC_MULTIPLE:
            return {
              id: key,
              value: formValue.map(({ value }) => value),
              filterLabel: filterConfig.label,
              filterValue: formValue.map(({ label }) => label),
              type: filterConfig.type,
            };
          default:
            return undefined;
        }
      })
      .filter((value) => value !== undefined) as Filter[];

export const translateFilters2FormValues = (filters: Filter[]): FormValues =>
  filters.reduce<FormValues>(
    (acc, { id, value, filterValue: label, type }) => ({
      ...acc,
      [id]:
        type === FilterType.DATE_RANGE ||
        type === FilterType.TEXT ||
        type === FilterType.CHECKBOX ||
        type === FilterType.SELECT
          ? value
          : type === FilterType.AUTOCOMPLETE_MULTIPLE ||
            type === FilterType.AUTOCOMPLETE_ASYNC_MULTIPLE
          ? fp
              .zip<string[], string[]>(value, label)
              .map(([value, label]) => ({ value, label }))
          : { value, label },
    }),
    {}
  );

export type FiltersQueryArgs = Record<string, string | boolean>;

const translateFilters2FiltersQueryArgs = (
  filters: Filter[]
): FiltersQueryArgs =>
  filters.reduce<FiltersQueryArgs>(
    (acc, { id, value }) => ({ ...acc, [id]: value }),
    {}
  );

const setFieldsValueMutator: Mutator<FormValues, Partial<FormValues>> = (
  [name, value],
  state,
  { changeValue }
) => {
  changeValue(state, name, () => value);
};

type UseFiltersFormArgs = {
  filterConfigs: FilterConfig[];
  initialFormValues?: FormValues;
  onApplyFilters: (filters: Filter[]) => void;
  onChangeFilters?: (filtersQueryArgs: FiltersQueryArgs) => void;
};

const useFiltersForm = ({
  filterConfigs,
  initialFormValues,
  onApplyFilters,
  onChangeFilters,
}: UseFiltersFormArgs) => {
  const checkIsMounted = useCheckIsMounted();

  const visibleFilterConfigs = useMemo(
    () => filterConfigs.filter(({ isFilterHidden }) => !isFilterHidden),
    [filterConfigs]
  );

  const translateFormValues2Filters = useMemo(
    () => getTranslateFormValues2Filters(visibleFilterConfigs),
    [visibleFilterConfigs]
  );

  // TODO: исправить паразитный ререндер при обновлении translateFormValues2Filters для suggested-полей
  const initialFilters = React.useMemo(
    () =>
      translateFormValues2Filters({
        ...initialFormValues,
      }).filter(({ value }) => !fp.isNil(value)),
    [initialFormValues]
    // [initialFormValues, translateFormValues2Filters]
  );

  const [selectedFilters, setSelectedFilters] =
    useState<Filter[]>(initialFilters);
  const [appliedFilters, setAppliedFilters] =
    useState<Filter[]>(initialFilters);
  const [hasFormErrors, setHasFormErrors] = useState<boolean>();
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string> | {}
  >({});

  React.useLayoutEffect(() => {
    if (checkIsMounted() && !!initialFilters.length) {
      setAppliedFilters(initialFilters);
      setSelectedFilters(initialFilters);
    }
  }, [initialFilters]);

  const canApplyFilters =
    !hasFormErrors && !fp.isEqual(selectedFilters, appliedFilters);

  const handleChangeFilters = useEvent((formState: FormState<FormValues>) => {
    const filters = translateFormValues2Filters(formState.values);
    const filtersQueryArgs = translateFilters2FiltersQueryArgs(filters);
    setSelectedFilters(filters);
    onChangeFilters?.(filtersQueryArgs);
  });

  const resetFilters = useEvent(() => {
    setAppliedFilters([]);
    setSelectedFilters([]);
    onApplyFilters([]);
    onChangeFilters?.({});
  });

  return {
    selectedFilters,
    appliedFilters,
    canApplyFilters,
    visibleFilterConfigs,
    setSelectedFilters,
    setAppliedFilters,
    setHasFormErrors,
    validationErrors,
    setValidationErrors,
    resetFilters,
    handleChangeFilters,
  };
};

type FiltersFormProps = {
  className?: string;
  formRef?: React.MutableRefObject<
    FormApi<FormValues, Partial<FormValues>> | undefined
  >;
  appliedFilters: Filter[];
  filterConfigs: FilterConfig[];
  locale: Language;
  calendarFixedRange?: number;
  setValidationErrors: (errors: Record<string, string> | {}) => void;
  isSingleFieldsColumn?: boolean;
  setHasFormErrors: (hasErrors: boolean) => void;
  onChangeFilters: (formState: FormState<FormValues>) => void;
};

const FiltersForm = React.memo<FiltersFormProps>(
  ({
    className,
    formRef,
    appliedFilters,
    locale,
    filterConfigs,
    isSingleFieldsColumn,
    calendarFixedRange,
    setValidationErrors,
    setHasFormErrors,
    onChangeFilters,
  }) => {
    const initialFormValues = React.useMemo(
      () => translateFilters2FormValues(appliedFilters),
      [appliedFilters]
    );

    const onValidateCalendarDates = useCallback(
      (values) => {
        if (values?.startDate && values?.endDate) {
          const createDate = validators.length.maxDays(
            calendarFixedRange as number,
            values?.startDate,
            values?.endDate
          );

          if (createDate) {
            setValidationErrors((prev: Record<string, string> | {}) => ({
              ...prev,
              createDate,
            }));
            setHasFormErrors(true);
          } else {
            setValidationErrors(() => ({}));
          }
        }
      },
      [calendarFixedRange]
    );

    const handleChangeFilters = useEvent(
      ({
        values,
        dirtyFields: _dirtyFields,
      }: {
        values: FormState<FormValues>;
        dirtyFields: Record<string, boolean>;
      }) => {
        if (calendarFixedRange) {
          onValidateCalendarDates(values);
        }
        onChangeFilters({ values });
      }
    );

    return (
      <Form<FormValues>
        initialValues={initialFormValues}
        mutators={{ setFieldsValue: setFieldsValueMutator }}
        onSubmit={constVoid}
      >
        {({ form, errors }) => {
          setHasFormErrors(!fp.isEmpty(errors));
          if (formRef) {
            fillRef(formRef)(form);
          }
          return (
            <React.Fragment>
              <Grid container className={className} spacing={3}>
                {filterConfigs.map((filterConfig) => (
                  <Grid
                    item
                    key={filterConfig.id}
                    xs={isSingleFieldsColumn ? 12 : 4}
                  >
                    <FilterByType locale={locale} {...filterConfig} />
                  </Grid>
                ))}
              </Grid>
              <FormSpy<FormValues>
                subscription={{ values: true, dirty: true, dirtyFields: true }}
                onChange={handleChangeFilters}
              />
            </React.Fragment>
          );
        }}
      </Form>
    );
  }
);

export type FiltersTableInTabsProps = {
  filtersTabs: TabProps[];
  filtersHistory: FilterSet[];
  savedFilters?: FilterSet[];
  locale: FiltersFormProps['locale'];
  formRef?: FiltersFormProps['formRef'];
  filters: FiltersFormProps['filterConfigs'];
  calendarFixedRange?: number;
  initialFilters?: UseFiltersFormArgs['initialFormValues'];
  onChangeFilters?: UseFiltersFormArgs['onChangeFilters'];
  onApplyFilters: UseFiltersFormArgs['onApplyFilters'];
  onAddFiltersHistory: (filters: Filter[]) => void;
  onSaveFilters?: (filters: Filter[], filterName: string) => void;
  onDeleteFilters?: (filterId: string) => void;
  onEditFilter?: (newFilterSet: FilterSet) => void;
  appliedInitFilters?: Filter[];
};

export const FiltersTableInTabs = ({
  filters: filterConfigs,
  filtersTabs,
  formRef,
  locale,
  filtersHistory = [],
  calendarFixedRange,
  initialFilters: initialFormValues,
  onApplyFilters,
  onChangeFilters,
  onAddFiltersHistory,
  appliedInitFilters,
}: FiltersTableInTabsProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const {
    selectedFilters,
    appliedFilters,
    canApplyFilters,
    visibleFilterConfigs,
    setSelectedFilters,
    setAppliedFilters,
    setHasFormErrors,
    validationErrors,
    setValidationErrors,
    resetFilters,
    handleChangeFilters,
  } = useFiltersForm({
    initialFormValues,
    filterConfigs,
    onChangeFilters,
    onApplyFilters,
  });

  const handleApplyFilters = useEvent((filters: Filter[]) => {
    setSelectedFilters(filters);
    setAppliedFilters(filters);
    onApplyFilters(filters);
    onAddFiltersHistory(filters);
  });

  const handleDelete = useEvent((id: string) => {
    const newSelectedFilters = selectedFilters.filter(
      (filter) => filter.id !== id
    );
    handleApplyFilters(newSelectedFilters);
  });

  const transformFilters = (filters: Filter[]) => {
    return filters.map((filter) => {
      return {
        id: filter.id,
        value: filter.value,
      };
    });
  };

  const isInitFilters = appliedInitFilters
    ? fp.isEqual(appliedInitFilters, transformFilters(appliedFilters))
    : false;

  return (
    <Box className={styles.filters}>
      <Box>
        <TabsComponent
          items={[filtersTabs[0], filtersTabs[2]] as TabProps[]}
          variant="standard"
          onChangeTab={setActiveTab}
        />
        <Stack className={styles.filtersContainer}>
          <Box className={styles.filtersContent}>
            {activeTab === 0 && (
              <>
                <FiltersForm
                  appliedFilters={appliedFilters}
                  calendarFixedRange={calendarFixedRange}
                  filterConfigs={visibleFilterConfigs}
                  formRef={formRef}
                  locale={locale}
                  setHasFormErrors={setHasFormErrors}
                  setValidationErrors={setValidationErrors}
                  onChangeFilters={handleChangeFilters}
                />
                {!fp.isEmpty(validationErrors) &&
                  Object.keys(validationErrors).map((error) => (
                    <p className={styles.error} key={error}>
                      {validationErrors[error]}
                    </p>
                  ))}
                <Box className={styles.filterActions}>
                  <Button
                    disabled={!canApplyFilters || !fp.isEmpty(validationErrors)}
                    onClick={() => handleApplyFilters(selectedFilters)}
                  >
                    {t('Применить')}
                  </Button>
                </Box>
              </>
            )}
            {activeTab === 2 && (
              <History
                applyFilters={handleApplyFilters}
                filters={filtersHistory}
                isHistory={true}
                noFilterText={t('Нет истории фильтров')}
              />
            )}
          </Box>
        </Stack>
      </Box>
      {(appliedInitFilters
        ? !isInitFilters
        : Boolean(appliedFilters.length)) && (
        <Box>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{t('Примененные фильтры')}</Typography>
            <Box>
              <Button variant="text" onClick={resetFilters}>
                {t('Сбросить фильтры')}
              </Button>
            </Box>
          </Stack>
          <Grid container mt={2} spacing={2}>
            {appliedFilters.map((filter) => (
              <FilterChip
                disabled={['startDate', 'endDate'].includes(filter.id)}
                filter={filter}
                key={filter.id}
                onDelete={handleDelete}
              />
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

type FiltersTableInDrawerProps = {
  locale: FiltersFormProps['locale'];
  formRef?: FiltersFormProps['formRef'];
  filters: FiltersFormProps['filterConfigs'];
  isOpen: boolean;
  setValidationErrors: (errors: Record<string, string> | {}) => void;
  initialFilters?: UseFiltersFormArgs['initialFormValues'];
  onChangeFilters?: UseFiltersFormArgs['onChangeFilters'];
  onApplyFilters: UseFiltersFormArgs['onApplyFilters'];
  onClose: VoidFunction;
};

export const FiltersTableInDrawer = React.memo<FiltersTableInDrawerProps>(
  ({
    locale,
    formRef,
    filters: filterConfigs,
    initialFilters: initialFormValues,
    isOpen,
    setValidationErrors,
    onClose,
    onChangeFilters,
    onApplyFilters,
  }) => {
    const { t } = useTranslation();
    const {
      selectedFilters,
      appliedFilters,
      canApplyFilters,
      visibleFilterConfigs,
      setAppliedFilters,
      setHasFormErrors,
      resetFilters,
      handleChangeFilters,
    } = useFiltersForm({
      initialFormValues,
      filterConfigs,
      onChangeFilters,
      onApplyFilters,
    });

    const drawerTopActions = useMemo(
      () => (
        <Button
          disabled={!selectedFilters.length}
          size="small"
          onClick={() => {
            resetFilters();
            onClose();
          }}
        >
          <Icon className={styles.refresh} name="RefreshXt" />
          {t('Сбросить')}
        </Button>
      ),
      [resetFilters, selectedFilters.length, onClose, t]
    );

    const handleApplyFilters = useEvent((filters: Filter[]) => {
      setAppliedFilters(filters);
      onApplyFilters(filters);
    });

    return (
      <Box className={styles.filters}>
        <Box>
          <Drawer
            cancelTitle={t('Отмена')}
            open={isOpen}
            submitDisabled={!canApplyFilters}
            submitTitle={t('Применить')}
            title={t('Фильтр')}
            topActions={drawerTopActions}
            onClose={onClose}
            onSubmit={() => {
              handleApplyFilters(selectedFilters);
              onClose();
            }}
          >
            <FiltersForm
              appliedFilters={appliedFilters}
              className={styles.filtersInDrawer}
              filterConfigs={visibleFilterConfigs}
              formRef={formRef}
              isSingleFieldsColumn={true}
              locale={locale}
              setHasFormErrors={setHasFormErrors}
              setValidationErrors={setValidationErrors}
              onChangeFilters={handleChangeFilters}
            />
          </Drawer>
        </Box>
      </Box>
    );
  }
);
