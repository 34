import React, { FC } from 'react';

import { Box } from '@mui/material';

import { Icon } from '@ibox/ui';

import css from './index.module.scss';

export const PRODUCT_GROUPS_ICONS = {
  milk: <Icon name="PgMilk" />,
  pharma: <Icon name="PgMedicines" />,
  alcohol: <Icon name="PgAlcohol" />,
  shoes: <Icon name="PgShoes" />,
  tobacco: <Icon name="PgTobacco" />,
  lp: <Icon name="PgClothes" />,
  appliances: <Icon name="PgAppliances" />,
  beer: <Icon name="PgBeer" />,
  water: <Icon name="PgWater" />,
  antiseptic: <Icon name="PgAntiseptic" />,
  laptops: <Icon name="PgScreen" />,
  telephones: <Icon name="PgTelephones" />,
  perfumery: <Icon name="PgPerfumery" />,
  tires: <Icon name="PgTires" />,
  conserve: <Icon name="PgConserve" />,
  softdrinks: <Icon name="PgSoftDrinks" />,
  chemistry: <Icon name="PgChemistry" />,
  coffeetea: <Icon name="PgCoffeeTea" />,
  dyes: <Icon name="PgDyes" />,
  grocery: <Icon name="PgGrocery" />,
  sweets: <Icon name="PgSweets" />,
  vegetableoil: <Icon name="PgVegetableOil" />,
  seafood: <Icon name="PgSeaFood" />,
  petfood: <Icon name="PgPetFood" />,
  autofluids: <Icon name="PgAutofluids" />,
  vetpharma: <Icon name="PgVetpharma" />,
  bio: <Icon name="PgBio" />,
  meat: <Icon name="PgMeat" />,
};

type ProductGroupIconProps = {
  pg: keyof typeof PRODUCT_GROUPS_ICONS;
};

export const ProductGroupIcon: FC<ProductGroupIconProps> = ({ pg }) => (
  <Box className={css['pg-cell']} data-testid="icon">
    <Box>{PRODUCT_GROUPS_ICONS[pg]}</Box>
  </Box>
);
