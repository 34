import { Translate } from '../components/translate';

export const PRODUCT_GROUPS_NAMES = {
  milk: 'Молочная продукция',
  pharma: 'Лекарственные препараты',
  alcohol: 'Алкогольная продукция',
  shoes: 'Обувные товары',
  tobacco: 'Табачная продукция',
  water: 'Упакованная питьевая вода',
  beer: 'Пиво и пивные напитки',
  appliances: 'Бытовая техника',
  lp: 'Лёгкая промышленность (текстиль, одежда)',
  otp: 'Альтернативная табачная продукция',
  antiseptic: 'Спиртосодержащая непищевая продукция',
  tires: 'Шины и покрышки пневматические резиновые новые',
  perfumery: 'Духи и туалетная вода',
  laptops: 'Ноутбуки',
  telephones: 'Телефонные аппараты',
  softdrinks: 'Соковая продукция и безалкогольные напитки',
  conserve: 'Консервированная продукция',
  chemistry: 'Парфюмерные и косметические средства и бытовая химия',
  coffeetea: 'Кофе, чай и их заменители',
  dyes: 'Красители, пигменты и лаки',
  seafood: 'Морепродукты',
  grocery: 'Бакалейная продукция',
  sweets: 'Шоколад и готовые пищевые продукты, содержащие какао',
  vegetableoil: 'Растительные и животные масла и жиры',
  petfood: 'Корма для домашних животных',
  autofluids: 'Масла смазочные и жидкости технические',
  vetpharma: 'Ветеринарные препараты',
  bio: 'Биологически активные добавки к пище',
  meat: 'Мясная пищевая продукция и экстракты животного происхождения',
};

const packageTypes = {
  consumer: '0',
  group: '1',
  set: 'SET',
};

export const PRODUCT_GROUPS_LIST = [
  {
    value: 'lp',
    label: Translate(PRODUCT_GROUPS_NAMES.lp),
    countries: ['AM', 'UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'shoes',
    label: Translate(PRODUCT_GROUPS_NAMES.shoes),
    countries: ['AM', 'UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'tobacco',
    label: Translate(PRODUCT_GROUPS_NAMES.tobacco),
    countries: ['AM', 'UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'pharma',
    label: Translate(PRODUCT_GROUPS_NAMES.pharma),
    countries: ['AM', 'UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'milk',
    label: Translate(PRODUCT_GROUPS_NAMES.milk),
    countries: ['AM', 'UZ'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.group,
      packageTypes.set,
    ],
  },
  {
    value: 'alcohol',
    label: Translate(PRODUCT_GROUPS_NAMES.alcohol),
    countries: ['AM', 'UZ'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.group,
      packageTypes.set,
    ],
  },
  {
    value: 'water',
    label: Translate(PRODUCT_GROUPS_NAMES.water),
    countries: ['AM', 'UZ'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.group,
      packageTypes.set,
    ],
  },
  {
    value: 'beer',
    label: Translate(PRODUCT_GROUPS_NAMES.beer),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.group,
      packageTypes.set,
    ],
  },
  {
    value: 'appliances',
    label: Translate(PRODUCT_GROUPS_NAMES.appliances),
    countries: ['AM', 'UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'antiseptic',
    label: Translate(PRODUCT_GROUPS_NAMES.antiseptic),
    countries: ['AM', 'UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'tires',
    label: Translate(PRODUCT_GROUPS_NAMES.tires),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'perfumery',
    label: Translate(PRODUCT_GROUPS_NAMES.perfumery),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.set],
  },
  {
    value: 'laptops',
    label: Translate(PRODUCT_GROUPS_NAMES.laptops),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.set],
  },
  {
    value: 'telephones',
    label: Translate(PRODUCT_GROUPS_NAMES.telephones),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.set],
  },
  {
    value: 'softdrinks',
    label: Translate(PRODUCT_GROUPS_NAMES.softdrinks),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'conserve',
    label: Translate(PRODUCT_GROUPS_NAMES.conserve),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'chemistry',
    label: Translate(PRODUCT_GROUPS_NAMES.chemistry),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'coffeetea',
    label: Translate(PRODUCT_GROUPS_NAMES.coffeetea),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'seafood',
    label: Translate(PRODUCT_GROUPS_NAMES.seafood),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'dyes',
    label: Translate(PRODUCT_GROUPS_NAMES.dyes),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'grocery',
    label: Translate(PRODUCT_GROUPS_NAMES.grocery),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'sweets',
    label: Translate(PRODUCT_GROUPS_NAMES.sweets),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'vegetableoil',
    label: Translate(PRODUCT_GROUPS_NAMES.vegetableoil),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'petfood',
    label: Translate(PRODUCT_GROUPS_NAMES.petfood),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'autofluids',
    label: Translate(PRODUCT_GROUPS_NAMES.autofluids),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'vetpharma',
    label: Translate(PRODUCT_GROUPS_NAMES.vetpharma),
    countries: ['AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'bio',
    label: Translate(PRODUCT_GROUPS_NAMES.bio),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'meat',
    label: Translate(PRODUCT_GROUPS_NAMES.meat),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
];

export const getProductGroupList = (t: (str: string) => string) => [
  {
    value: 'lp',
    label: t(PRODUCT_GROUPS_NAMES.lp),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'shoes',
    label: t(PRODUCT_GROUPS_NAMES.shoes),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'tobacco',
    label: t(PRODUCT_GROUPS_NAMES.tobacco),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'pharma',
    label: t(PRODUCT_GROUPS_NAMES.pharma),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'milk',
    label: t(PRODUCT_GROUPS_NAMES.milk),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.group,
      packageTypes.set,
    ],
  },
  {
    value: 'alcohol',
    label: t(PRODUCT_GROUPS_NAMES.alcohol),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.group,
      packageTypes.set,
    ],
  },
  {
    value: 'water',
    label: t(PRODUCT_GROUPS_NAMES.water),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging:
      window?.env?.COUNTRY_CODE === 'AM'
        ? [packageTypes.consumer, packageTypes.set]
        : [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'beer',
    label: t(PRODUCT_GROUPS_NAMES.beer),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging:
      window?.env?.COUNTRY_CODE === 'AM'
        ? [packageTypes.consumer]
        : [packageTypes.consumer, packageTypes.group, packageTypes.set],
  },
  {
    value: 'appliances',
    label: t(PRODUCT_GROUPS_NAMES.appliances),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'antiseptic',
    label: t(PRODUCT_GROUPS_NAMES.antiseptic),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'tires',
    label: t(PRODUCT_GROUPS_NAMES.tires),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'perfumery',
    label: t(PRODUCT_GROUPS_NAMES.perfumery),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'laptops',
    label: t(PRODUCT_GROUPS_NAMES.laptops),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.set],
  },
  {
    value: 'telephones',
    label: t(PRODUCT_GROUPS_NAMES.telephones),
    countries: ['UZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.set],
  },
  {
    value: 'softdrinks',
    label: t(PRODUCT_GROUPS_NAMES.softdrinks),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'conserve',
    label: t(PRODUCT_GROUPS_NAMES.conserve),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'chemistry',
    label: t(PRODUCT_GROUPS_NAMES.chemistry),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'coffeetea',
    label: t(PRODUCT_GROUPS_NAMES.coffeetea),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'dyes',
    label: t(PRODUCT_GROUPS_NAMES.dyes),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'grocery',
    label: t(PRODUCT_GROUPS_NAMES.grocery),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'sweets',
    label: t(PRODUCT_GROUPS_NAMES.sweets),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'vegetableoil',
    label: t(PRODUCT_GROUPS_NAMES.vegetableoil),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'seafood',
    label: t(PRODUCT_GROUPS_NAMES.seafood),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'petfood',
    label: t(PRODUCT_GROUPS_NAMES.petfood),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'autofluids',
    label: t(PRODUCT_GROUPS_NAMES.autofluids),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'vetpharma',
    label: t(PRODUCT_GROUPS_NAMES.vetpharma),
    countries: ['AM'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'bio',
    label: t(PRODUCT_GROUPS_NAMES.bio),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
  {
    value: 'meat',
    label: t(PRODUCT_GROUPS_NAMES.meat),
    countries: ['AM'],
    permittedTypesOfPackaging: [
      packageTypes.consumer,
      packageTypes.set,
      packageTypes.group,
    ],
  },
];

export const MARK_CODE_TYPES = [
  { value: '0', label: Translate('КМ для потребительской упаковки') },
  { value: '1', label: Translate('КМ для групповой упаковки') },
  { value: 'SET', label: Translate('КМ для набора') },
];

export const MARK_CODE_TYPES_NAMES = {
  0: 'КМ для потребительской упаковки',
  UNIT: 'КМ для потребительской упаковки',
  1: 'КМ для групповой упаковки',
  GROUP: 'КМ для групповой упаковки',
  SET: 'КМ для набора',
};

export const PRODUCT_GROUPS = {
  milk: 'milk',
  pharma: 'pharma',
  alcohol: 'alcohol',
  shoes: 'shoes',
  tobacco: 'tobacco',
  lp: 'lp',
  appliances: 'appliances',
  beer: 'beer',
  water: 'water',
  antiseptic: 'antiseptic',
  perfumery: 'perfumery',
  tires: 'tires',
  laptops: 'laptops',
  telephones: 'telephones',
  conserve: 'conserve',
  softdrinks: 'softdrinks',
  chemistry: 'chemistry',
  coffeetea: 'coffeetea',
  dyes: 'dyes',
  seafood: 'seafood',
  grocery: 'grocery',
  sweets: 'sweets',
  vegetableoil: 'vegetableoil',
  petfood: 'petfood',
  autofluids: 'autofluids',
  vetpharma: 'vetpharma',
  bio: 'bio',
  meat: 'meat',
};

export const DOCUMENT_TYPES = {
  AGGREGATION: 'AGGREGATION',
  UTILISATION: 'UTILISATION',
  DROPOUT: 'DROPOUT',
  INTRODUCTION: 'INTRODUCTION',
};

export const DOCUMENT_TYPES_NAMES = {
  [DOCUMENT_TYPES.AGGREGATION]: Translate('Агрегация'),
  [DOCUMENT_TYPES.UTILISATION]: Translate('Нанесение'),
  [DOCUMENT_TYPES.DROPOUT]: Translate('Списание (отбраковка)'),
  [DOCUMENT_TYPES.INTRODUCTION]: Translate('Ввод в оборот'),
};
